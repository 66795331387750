<div class="message-snackbar flex gap-2" role="status" aria-relevant="additions">
    <span class="error-message" matSnackBarLabel>
        <mat-icon class="shrink-0">warning</mat-icon>
        <span *ngIf="showStatus">
            <b> Status Code: {{ data.status }}</b>
        </span>
        {{ data.message }}
    </span>
    <span matSnackBarActions>
        <button mat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">OK</button>
    </span>
</div>