import { Component, Inject, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-message-snackbar',
  templateUrl: './message-snackbar.component.html',
  styleUrls: ['./message-snackbar.component.scss'],
})
export class MessageSnackbarComponent {
  snackBarRef = inject(MatSnackBarRef);
  
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { message: string; status?: number }
  ) {}

  get showStatus(): boolean {
    return this.data?.status && this.data?.status > 0 ? true : false;
  }
}
